
















































































































































































































































































































































































































































































































































































#content {
  span {
    font-size: 16px;
  
    strong {
      font-weight: 700;
    }
  }
}
.infoWindowActions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    font-weight: 800;

    &:hover {
      text-decoration-line: underline;
    }
  }
}
